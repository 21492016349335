
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_MANUFACTURE_URL = BASE_API_URL+"manufactures"


@Component
export default class ManufacturesMixin extends Vue {

  getManufactures(data: any, tag: string) {
    return this.$httpGetQuery(BASE_MANUFACTURE_URL, tag,data);
  }

  getManufacture(id: number, tag: string) {
    return this.$httpGet( BASE_MANUFACTURE_URL+"/"+id, tag);
  }

  destroyManufacture(id: string|number, tag: string) {
    return this.$httpDelete( BASE_MANUFACTURE_URL+"/"+id, tag);
  }

  createManufacture(data: any, tag: string) {
    return this.$httpPost(BASE_MANUFACTURE_URL, tag, data);
  }

  updateManufacture(data: any, tag: string) {
     return this.$httpPut( BASE_MANUFACTURE_URL+"/"+data.id, tag, data);
  }
}
