








































































import { Source } from "@/models";
import CreateModal from "@/components/Manufacture/Create.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import EditModal from "@/components/Manufacture/Edit.vue";
import EmptyView from "@/components/EmptyView.vue";
import {
  Vue,
  Component,
  Prop,
  Mixins,
  Watch,
  Ref
} from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import ManufacturesMixin from "@/mixins/http/ManufacturesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { Manufacture, User } from "@/models";
import Search from "@/components/VEntrySearch.vue";
import { easync } from "@/utils/http";
import { showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";

const getTag = "get_manufactures";
const destroyTag = "destroy_manufacture";

@Component({
  components: {
    EditModal,
    VPageContentSticky,
    DataTable,
    CreateModal,
    ConfirmModal,
    EmptyView,
    Search
  }
})
export default class ManufactureList extends Mixins(ManufacturesMixin) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;
  @Ref() createModal: HTMLDivElement;
  @Ref() editModal: HTMLDivElement;
  @Ref() confirmModal: HTMLDivElement;

  @Getter currentUser!: User;

  items: Manufacture[] = [];
  page = 1;
  search: string | null = null;

  columns = [
    { name: "name", label: "Nome" },
    { name: "total", label: "Totale" },
    { name: "actions", label: "" }
  ];

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const products = await this.getManufactures(this.payload, getTag);
      return products;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: {items: Manufacture[]}) {
    this.page = 1;
    this.items = items.items;
  }

  onCreated(item: Manufacture) {
    this.items.unshift(item);
  }

  onUpdated(item: Manufacture) {
    const index = this.items.findIndex(o => item.id === o.id);
    Vue.set(this.items, index, item);
  }

  onDeleted(id: string) {
    this.items = this.items.filter(o => id !== o.id);
  }

  openCreateModal() {
    (this.createModal as any).show();
  }

  openEditModal(item: Manufacture) {
    (this.editModal as any).show(item);
  }

  async openConfirmModal(item: Manufacture) {
    const [data, errors] = await easync((this.confirmModal as any).confirm("Sei sicuro di eliminare questa lavorazione?"));
    if (errors) {
      showToast("Errore durante l'eliminazione", { type: "error" });
      return;
    }

    if (data) {
      this.destroyItem(item.id);
    }
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  async destroyItem(id: string) {
    const [data, errors] = await easync(this.destroyManufacture(id, destroyTag));
    if (errors) {
      showToast("Si è verificato un errore durante l'eliminazione", { type: "error" });
      return;
    }

    this.onDeleted(id);
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data = {
      per: 20,
      page: this.page,
      filter: {
        company: sourceStore.currentSource?.company?.id,
        term: this.search
      }
    };
    return data;
  }

  async loadItems() {
    const [data, errors] = await easync(this.getManufactures(this.payload, getTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
