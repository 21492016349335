






















import { Component, Prop, Vue } from 'vue-property-decorator';
import VModal from '@/components/VModal.vue';

@Component({
  components: {
    VModal
  }
})
export default class ConfirmModal extends Vue {
  @Prop({ default: 'Confermi?'}) title? : string;

  modal!: VModal;
  content = '';

  confirmed!: any|null;
  canceled!: any|null;

  mounted() {
		this.modal = this.$refs.modal as VModal;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.confirmed) {
      this.confirmed();
    }

    this.confirmed = null;
    this.modal.hide();
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  confirm(content: string|undefined = undefined): Promise<boolean> {
    this.modal.show();

    if(content) {
      this.content = content;
    }

    return new Promise((resolve) => {
      this.confirmed = () => {
        resolve(true);
      };

      this.canceled = () => {
        resolve(false);
      };
    });
  }
}
